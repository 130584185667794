@import '~styles/base';

.container {
  @include size(100%, 75px);
  @include flex($direction: row, $justify: space-around, $align: center);

  padding: 0 12px;
  overflow: 'hidden';
  
  .icon {
    @include size(28px, 28px);
  }
}

.link {
  cursor: pointer;
}

.divider {
  @include size(100%, 1px);

  background-color: $color-gray-lighter;
}
