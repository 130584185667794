@import '~styles/base';

.container {
  @include size(100%, 83px);
  @include flex($align: center, $justify: space-between);

  cursor: pointer;
  padding: 0 12px 0 14px;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  .label {
    @include font(h20, $coal);

    max-width: 330px;
  }

  .switchWrapper {
    @include size(62px, 33px);
    @include border-radius(18px);
    @include font(h15Semi, $snow);
    @include relative();

    background-color: $snow;
    border: 2px solid $color-gray;
  }

  .oval {
    @include size(44px, 33px);
    @include border-radius(18px);
    @include flex($justify: center, $align: center);

    border: 2px solid $snow;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.27);
  }

  .withAnim {
    transition: all .2s ease-out;
  }

  .selected {
    margin: -2px 0 0 16px;
    background-color: $color-green;
  }

  .unselected {
    margin: -2px 0 0 -2px;
    background-color: $color-red-darkest;
  }

  &.disabled{
    cursor: not-allowed;
  }
}

.borderBottom {
  border-bottom: 1px solid $color-gray-light;
}
