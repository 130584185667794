@import '~styles/base';

.option {
  flex-direction: row;
  display: flex;
  width: 100%;
  min-height: 56px;
  align-items: center;
  justify-content: space-around;
}

.lastOption {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 36px;
}

.optionLabel {
  @include font(h13Med, $coal);

  margin-top: 10px;
  flex-grow: 1;
  cursor: pointer;
}
