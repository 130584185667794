
@import '~styles/base';

.root {
  @include absolute(top 30px left 0);

  transition: 200ms ease-in-out;

  .container {
    @include flex($justify: space-around);

    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 17px;
  
    .divider {
      @include size(1px, 44px);

      margin-top: 10px;
      opacity: .6;
      background-color: $color-gray;
    }
  }
}

.arrow {
  @include absolute(top 50%);
  @include size(30px);

  align-self: center;
  z-index: 999;
  margin-top: -5px;
  filter: grayscale(100%);
  opacity: .8;
  animation: fadeIn .5s ease-in;
}

.arrowLeft {
  left: 5px;
  transform: rotate(180deg);
}

.arrowRight {
  right: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: .8;
  }
}