@import '~styles/base';

.container {
  @include size(392px, auto);
  @include relative();
  @include flex();
  @include border-radius(8px);

  margin: 0 auto 30px auto;


  @include media-breakpoint-down (md) {
    width: 80vw;
  }


  .avatar {
    @include size(52px);
    @include border-radius(50%);

    object-fit: cover;
    box-shadow: 0 0 21px 0 rgba(15, 15, 15, 0.3);
    border: solid 3px $snow;
    margin-right: 17px;
  }

  .content {
    @include size(300px, auto);
    @include flex($direction: column);

    @include media-breakpoint-down (md) {
      @include size(auto, auto);
    }

    .date {
      @include font(h12Med, $color-gray-primary);
    }

    .message {
      @include font(h15, $color-gray-darker-light);

      margin: 20px 0;
      white-space: pre-wrap;
      word-break: break-word;
    }

    .by {
      @include font(h12Med, $color-gray-primary);
      @include flex();
    }

    .name {
      @include font(h12Med, $blue-dark-lighter);

      height: auto;
      cursor: pointer;
      margin-left: 3px;
    }
  }
}