// Variables
// Palette
$color-shade-amount: 15%;
$color-trans-amount: 0.5;

$base-gradient: linear-gradient(#422ba7, #0093e8);
$color-transparent: transparent;
$font-light-color: #fefeff;
$font-light-color2: #fffefe;
$font-light-color3: #f5f5f5;
$color-gray: #ddd;
$color-gray-light: #eee;
$color-gray-primary: #bfbfbf;
$color-gray-lighter: #e1e1e1;
$color-gray-dark: #acacac;
$color-gray-darker-light: #5e5e5e;
$color-gray-dark2: #7f7f7f;
$color-gray-darker: #555555;
$color-gray-darker2: #585858;
$color-purple: #5243aa;
$grayMishka: #E4E0E7;
$grayMishka2: #E0DFE3;
$blue-light: #7594da;
$blue-primary: #048de4;
$blue: #289FE1;
$blue-primary-opacity: #048ee44f;
$blue-primary-light: #048ce4;
$blue-darker: #0290e6;
$blue-darker-light: #1b69cd;
$blue-dark: #235bc5;
$blue-dark-light: #0291e6;
$blue-dark-lighter: #0198e6;
$blueCerulean: #038DE5;
$blueCurious: #1A8FE2;
$purple-primary: #402ea8;
$purple-primary-dark: #3f2faa;
$purple-light: #146fcd;
$color-red: #f00f0f;
$color-blue: #0095e7;
$color-red-darker: #f20000;
$color-red-darkest: #9e0b0e;
$redRoman: #D65252;
$color-green: #249b00;
$snow: #ffffff;
$dark: #030303;
$coal: #000000;
$green: #2c9a18;
$greenForest: #39992A;
$greenMeadow: #1EA86E;
$greenEmerald: #56CF62;
$blueEnabled: #1f96df;
$light: #f9f9f9;
$black: black;
$web-orange: #ffa500;
$color-bittersweet: #fe6464;

// Bootstrap reboot
$enable-hover-media-query: false;
$enable-deprecation-messages: false;

$headings-margin-bottom: 8px;
$font-family-base: 'Work Sans', sans-serif;
$font-size-base: 16px; // Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-weight-base: normal;
$line-height-base: $font-size-base;
$dt-font-weight: $font-weight-base;

$font-size-10: 10.5px;
$font-size-12: 12px;
$font-size-13: 13.5px;
$font-size-14: 14px;
$font-size-14half: 14.5px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-16half: 16.5px;
$font-size-18: 18px;
$font-size-19: 19.5px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22.5px;
$font-size-24: 24px;
$font-size-26: 26.5px;
$font-size-27: 27px;
$font-size-28: 28px;
$font-size-31: 31.5px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-37: 37.5px;
$font-size-45: 45px;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// Grid
$grid-columns: 12;
$grid-fluid: false;
$grid-gutter-widths: (down sm: 40px, up md: 30px);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

// Positioning
$z-indexes: ('modal', 'intercom', 'tooltip', 'body');

// Spacing
$spacing-sizes: (
  0: 0,
  1: 15px,
  2: 30px,
  3: 50px,
  4: 90px,
  5: 120px,
  6: 180px,
  7: 200px,
  8: 300px,
);
$spacing-important: false;

// Typography
$typography: (
  base: (
    base: (
      font-size: $font-size-base,
      font-weight: 400,
      line-height: 30px,
    ),
  ),
  h10Semi: (
    base: (
      font-size: $font-size-10,
      font-weight: 600,
    ),
  ),
  h10Reg: (
    base: (
      font-size: $font-size-10,
      font-weight: 400,
    ),
  ),
  h10: (
    base: (
      font-size: $font-size-10,
      font-weight: 500,
    ),
  ),
  h12: (
    base: (
      font-size: $font-size-12,
      font-weight: 400,
    ),
  ),
  h12Semi: (
    base: (
      font-size: $font-size-12,
      font-weight: 600,
    ),
  ),
  h12Med: (
    base: (
      font-size: $font-size-12,
      font-weight: 500,
    ),
  ),
  h13: (
    base: (
      font-size: $font-size-13,
      font-weight: 400,
    ),
  ),
  h13Med: (
    base: (
      font-size: $font-size-13,
      font-weight: 500,
    ),
  ),
  h13Bold: (
    base: (
      font-size: $font-size-13,
      font-weight: 700,
    ),
  ),
  h13Semi: (
    base: (
      font-size: $font-size-13,
      font-weight: 600,
    ),
  ),
  h14: (
    base: (
      font-size: $font-size-14,
      font-weight: 500,
    ),
  ),
  h14Reg: (
    base: (
      font-size: $font-size-14,
      font-weight: 400,
    ),
  ),
  h14Thin: (
    base: (
      font-size: $font-size-14,
      font-weight: 200,
    ),
  ),
  h14Semi: (
    base: (
      font-size: $font-size-14half,
      font-weight: 600,
    ),
  ),
  h14Bold: (
    base: (
      font-size: $font-size-14,
      font-weight: 600,
    ),
  ),
  h14BoldExtra: (
    base: (
      font-size: $font-size-14,
      font-weight: 700,
    ),
  ),
  h15: (
    base: (
      font-size: $font-size-15,
      font-weight: 500,
    ),
  ),
  h15Reg: (
    base: (
      font-size: $font-size-15,
      font-weight: 400,
    ),
  ),
  h15Semi: (
    base: (
      font-size: $font-size-15,
      font-weight: 600,
    ),
  ),
  h15Bold: (
    base: (
      font-size: $font-size-15,
      font-weight: 700,
    ),
  ),
  h16: (
    base: (
      font-size: $font-size-16,
      font-weight: 400,
    ),
  ),
  h16Med: (
    base: (
      font-size: $font-size-16,
      font-weight: 500,
    ),
  ),
  h16Semi: (
    base: (
      font-size: $font-size-16,
      font-weight: 600,
    ),
  ),
  h18: (
    base: (
      font-size: $font-size-18,
      font-weight: 500,
    ),
  ),
  h18Reg: (
    base: (
      font-size: $font-size-18,
      font-weight: 400,
    ),
  ),
  h18Semi: (
    base: (
      font-size: $font-size-18,
      font-weight: 600,
    ),
  ),
  h18Extra: (
    base: (
      font-size: $font-size-18,
      font-weight: 800,
    ),
  ),
  h19: (
    base: (
      font-size: $font-size-19,
      font-weight: 500,
    ),
  ),
  h20Reg: (
    base: (
      font-size: $font-size-20,
      font-weight: 400,
    ),
  ),
  h20: (
    base: (
      font-size: $font-size-20,
      font-weight: 500,
    ),
  ),
  h20Semi: (
    base: (
      font-size: $font-size-20,
      font-weight: 600,
    ),
  ),
  h20Bold: (
    base: (
      font-size: $font-size-20,
      font-weight: 700,
    ),
  ),
  h21: (
    base: (
      font-size: $font-size-21,
      font-weight: 400,
    ),
  ),
  h21Med: (
    base: (
      font-size: $font-size-21,
      font-weight: 500,
    ),
  ),
  h21Semi: (
    base: (
      font-size: $font-size-21,
      font-weight: 600,
    ),
  ),
  h22Semi: (
    base: (
      font-size: $font-size-22,
      font-weight: 600,
    ),
  ),
  h22: (
    base: (
      font-size: $font-size-22,
      font-weight: 500,
    ),
  ),
  h24: (
    base: (
      font-size: $font-size-24,
      font-weight: 500,
    ),
  ),
  h24Bold: (
    base: (
      font-size: $font-size-24,
      font-weight: 700,
    ),
  ),
  h26: (
    base: (
      font-size: $font-size-26,
      font-weight: 500,
    ),
  ),
  h27Med: (
    base: (
      font-size: $font-size-27,
      font-weight: 500,
    ),
  ),
  h27: (
    base: (
      font-size: $font-size-27,
      font-weight: 600,
    ),
  ),
  h28Med: (
    base: (
      font-size: $font-size-28,
      font-weight: 600,
    ),
  ),
  h31: (
    base: (
      font-size: $font-size-31,
      font-weight: 600,
    ),
  ),
  h31Med: (
    base: (
      font-size: $font-size-31,
      font-weight: 500,
    ),
  ),
  h34Med: (
    base: (
      font-size: $font-size-34,
      font-weight: 500,
    ),
  ),
  h36: (
    base: (
      font-size: $font-size-36,
      font-weight: 300,
    ),
  ),
  h37: (
    base: (
      font-size: $font-size-37,
      font-weight: 400,
    ),
  ),
  h45: (
    base: (
      font-size: $font-size-45,
      font-weight: 400,
    ),
  ),
);
