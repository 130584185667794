@import '~styles/base';

.wrapper {
  @include flex($direction: column);

  &.sideMenu {
    @include media-breakpoint-down (md) {
      flex: 1;
      display: flex;
    }
  }

  .divider {
    @include size(420px, 1px);

    background-color: $snow;
    opacity: 0.3;
    margin-left: 51px;

    @include media-breakpoint-down (md) {
      @include size(80vw, 1px);
    }

    &.small {
      @include size(120px, 1px);
    }

    &.sideMenuDivider {
      @include media-breakpoint-down (md) {
        display: none;
      }
    }
  }

  .container {
    @include size(468px, 63px);
    @include flex($align: center);

    transition: all 0.2s ease-in-out;
    cursor: pointer;
    opacity: 0.72;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    @include media-breakpoint-down (md) {
      @include size(auto, 63px);
    }

    &.small {
      @include size(174px, 63px);
    }

    .image {
      @include size(44px, auto);

      object-fit: contain;
      margin-right: 18px;

      // fixes for Safari && Firefox
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      transform: rotate(0);
      fill: $snow;

      &.email {
        transform: scale(0.76);
      }

      &.animate {
        animation: fadeInIcon .3s ease-out forwards;
      }

      @keyframes fadeInIcon {
        0% {
          opacity: 0.5;
          transform: scale(0.8);
        }

        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .label {
      @include size(100%, 100%);
      @include font(h18, $snow);
      @include flex($align: center);

      &.small {
        @include font(h18Semi, rgba(255, 255, 255, 0.72));
      }
    }

    .unread {
      @include size(32px);
      @include border-radius(50%);
      @include absolute(right 15px);

      text-align: center;
      color: $snow;
      line-height: 32px;
      animation: fadeIn 0.3s ease-in-out;
      background-color: $color-red;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: scale(0);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.selected {
      opacity: 1;

      svg {
        opacity: 1;
      }

      .label {
        @include font(h18Extra, $snow);
        opacity: 1;
      }
    }

    &.sideMenu {
      @include media-breakpoint-down (md) {
        @include size(auto, 63px);
        flex: 1;
        display: flex;

        .label {
          display: none;
        }

        img {
          margin-right: 0px;
          @include size(32px, auto);
        }
      }
    }

    &:hover,
    &:focus {
      opacity: 1;

      .label {
        color: $snow !important;
      }
    }
  }
}