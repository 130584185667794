@import '~styles/base';

.container {
  @include size(415px, auto);
  @include flex($direction: column);

  padding-top: 50px;
  overflow: hidden;
  align-items: center;

  @include media-breakpoint-down (md) {
    @include size(90vw, auto);

    max-height: 80vh;
  }

  .imageBackground {
    @include size(415px, 158px);
    @include absolute(top 0);

    @include media-breakpoint-down (md) {
      width: 100%;
    }
  }

  .match {
    @include font(h45, $purple-primary);

    line-height: 30px;
    margin: 15px 0 19px 0;
  }

  .matchLabel {
    @include font(h21Semi, $coal);

    line-height: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .blueInfo {
    @include font(h27, $blue-darker);

    line-height: 36px;
    text-align: center;
  }

  .viewProfile {
    @include size(350px, 70px);
    @include border-radius(35px);
    @include flex();

    cursor: pointer;
    margin-top: 20px;
    background-color: $blue-darker;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s ease-in;

    @include media-breakpoint-down (md) {
      width: 80vw;
    }

    .label {
      @include font(h22Semi, $font-light-color2);
    }

    &:hover {
      box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .keepSwiping {
    @include font(h16Semi, $color-gray-primary);

    cursor: pointer;
    transition: transform .2s ease-in-out;
    transform: scale(1);
    margin: 22px 0;

    &:hover {
      transform: scale(1.03);
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(415px, auto);
  @include border-radius(22.5px);
  @include flex($direction: column);
  @include relative();
  @include p(0);
}