@import '~styles/base';

.indicator {
  margin-top: 330px;
}

.container {
  @include size(100%);
  @include flex($justify: center, $align: center, $direction: column);

  margin: 35px;

  @include media-breakpoint-down (md) {
    padding-bottom: 36px;
  }
}
