@import '~styles/base';

.container {
  @include size(434px, auto);
  @include flex($justify: center, $align: center, $direction: column);

  border-radius: 10px;
  margin-bottom: 12px;
  background-color: $snow;

  @include media-breakpoint-down (md) {
    @include size(90vw, auto);
  }
}

.section {
  @include size(100%, 55px);

  padding: 0 20px;
  border-bottom: 1px solid #E7ECF3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }

  .title {
    @include font(h14, #1D232E);
  }

  .label {
    @include font(h14, #5D6470);
  }
}
