@import "~styles/base";

.container {
  @include size(100%, 82px);
  @include relative();
  @include flex($direction: column, $align: center);

  background-color: transparent;
  align-items: center;
  transition: box-shadow .2s ease-in-out;
  -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

  .divider {
    @include size(86%, 2px);

    margin-left: 82px;
    align-self: flex-start;
    transition: background-color .2s ease-in-out;
    background-color: $color-gray-light;
  }

  .content {
    @include size(100%, 51px);
    @include flex($align: center);

    margin: 18px 0;

    @include media-breakpoint-down (md) {
      margin: 9px 0;
    }
  
    .avatar {
      @include size(51px);
      @include border-radius(50%);

      object-fit: cover;
      box-shadow: 0 0 21px 0 rgba(15, 15, 15, 0.3);
      border: solid 3px $snow;
      margin: 0 17px 0 12px;

      @include media-breakpoint-down (md) {
        margin: 0 17px 0 8px;
      }
    }

    .infoBlock {
      @include size(-webkit-fill-available, auto);
      @include flex($direction: column);

      padding-left: 16px;
      padding-right: 48px;

      @include media-breakpoint-down (md) {
        padding-right: 32px;
      }

      .row {
        @include size(-webkit-fill-available, auto);
        @include flex($align: flex-start, $justify: space-between);

        .title {
          @include font(h18, $blue-darker, $align: left);
          @include size(auto, 22.5px);
  
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-down (md) {
            @include font(h16, $blue-darker, $align: left);
          }

          &.unread {
            @include font(h18Semi, $blue-darker, $align: left);

            @include media-breakpoint-down (md) {
              @include font(h16Semi, $blue-darker, $align: left);
            }
          }
        }

        .date {
          @include size(auto, auto);
          @include font(h12Med, $color-gray-primary, $align: right);
        }
      }

      .message {
        @include font(h15, $color-gray-darker-light, $align: left);
        @include size(auto, auto);
  
        white-space: nowrap;
        overflow: hidden;
        margin-top: 5px;
        text-overflow: ellipsis;

        &.unread {
          @include font(h15Semi, $coal, $align: left);
        }
      }
    }

    .arrow {
      @include absolute(right 15px top 31px);
      @include size(7px, 13px);
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;

    .divider {
      background-color: $color-transparent;
    }
  }
}
