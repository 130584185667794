@import '~styles/base';

.container {
  background: transparent;
  border: 0;

  &:focus {
    outline-color: $color-bittersweet;
  }
}
