@import '~styles/base';

.container {
  @include size(434px, 516px);
  @include flex($direction: column, $align: center);
  
  top: 0px !important;
  border-radius: 22.5px !important;
  opacity: 1 !important;
  padding-top: 28px;
  overflow: hidden;
  background-color: $snow !important;
  box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.05) !important;

  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
   -khtml-user-select: none !important; /* Konqueror HTML */
     -moz-user-select: none !important; /* Firefox */
      -ms-user-select: none !important; /* Internet Explorer/Edge */
          user-select: none !important; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  &:hover {
    box-shadow: 0px 13px 87px 0 rgba(0, 0, 0, 0.15) !important;
  }

  .title {
    @include font(h22Semi, $color-gray-darker, $align: center);
    
    line-height: 23px;
    margin: 26px 0 30px 0;
  }

  .desc {
    @include font(h16, $coal);

    padding: 0 47px;
    overflow: hidden;
    text-align: center;
    margin-top: 20px;
  }

  .descNoMargin {
    @include font(h16, $coal);

    padding: 0 47px;
    overflow: hidden;
    text-align: center;

    a {
      @include font(h16Semi, $coal);

      cursor: pointer;
      text-decoration: underline;
    }
  }
}
