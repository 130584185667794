@import '~styles/base';

.container {
  @include flex($align: center, $justify: center);
  @include font(h16Semi, $snow);
  @include size(auto, 50px);

  background: $color-transparent;
  border: 0;

  &:focus {
    outline-color: $color-bittersweet;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.rounded {
  @include border-radius(25px);
}

.text {
  &:focus,
  &:hover {
    background-color: rgba($snow, 0.1);
  }
}

.outlined {
  border: 1px solid $snow;

  &:focus,
  &:hover {
    background-color: rgba($snow, 0.1);
  }
}

.contained {
  color: $blue-darker;
  background: $snow;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: darken($snow, 5%);
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

.preview {
  cursor: not-allowed;
  pointer-events: none;

  &:disabled {
    opacity: 1;
  }
}
