@import '~styles/base';

.title {
  font-size: 1.5em;
}

.content {
  @include flex($align: center);

  margin: 0;
}

.menuWrapper {
  @include flex($direction: column);
  @include absolute(top 35px left 35px);

  position: fixed;
  z-index: 10; 
}

.logo {
  @include size(176px, 32px);

  object-fit: contain;
  margin-bottom: 73px;
}