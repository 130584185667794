@import '~styles/base';

.btn {
  @include border-radius(27.9px);
  @include size(283px, 57px);
  @include flex($justify: center, $align: center);
  @include font(h13Med);

  background-color: $color-transparent;
  border: 1px solid $snow;
  color: $snow;
  text-decoration: none;
  outline: none;
  transition: box-shadow .2s ease-in;
  margin: 9px 0;

  &:hover {
    box-shadow: 2px 5px 20px 0 rgba($coal, 0.15);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
