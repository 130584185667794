@import '~styles/base';

.container {
  @include size(100%, 96px);
  @include relative();

  cursor: pointer;
  background-color: $snow;
  padding-left: 24px;
  justify-content: flex-start;
  transition: box-shadow 0.2s ease-out;
  border-bottom: 2px solid $color-gray-light;

  &:last-child {
    border-bottom: none;
  }

  &.noBorder {
    border-bottom: none;
  }

  &.highlighted {
    background-color: rgba($blue-primary, 0.1);
  }

  .photo_border {
    @include size(51px);

    min-width: 51px;
    border: 3px solid $snow;
    border-radius: 100%;
    display: inline-block;
    box-shadow: 0 0 21px 0 rgba(15, 15, 15, 0.3);

    .photo {
      @include size(100%);
      @include border-radius(50%);

      object-fit: cover;
    }
  }

  &:hover,
  &:focus {
    background-color: darken($snow, 5%);
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.15);
    z-index: 2;
  }

  .name_container {
    display: flex;
    margin-left: 31px;
    text-align: left;
    flex-direction: column;

    .name {
      @include font(h22Semi, $color-blue);

      margin-bottom: 0;
    }

    .info {
      @include font(h12, $dark);

      margin-bottom: 0;
    }
  }

  .check {
    @include absolute(top 10px right 16px);
    @include flex($align: center);

    height: 36px;
    cursor: pointer;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-out;

    &:hover {
      box-shadow: 0 0 8px 0 rgba(15, 15, 15, 0.2);
    }

    svg {
      fill: $color-gray-dark;
    }

    p {
      @include font(h12, $color: $color-blue);

      margin: 0;

      &::after {
        @include size(6px, 10px);
        @include absolute(right 8.6px top 5px);

        content: '';
        display: block;
        border: solid $snow;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        pointer-events: none;
      }
    }
  }
}
