@import '~styles/base';

.container {
  @include size(100x, auto);
  @include border-radius(8px);

  cursor: pointer;
  margin-bottom: 15px;
  align-items: center;
  padding: 10px;
  justify-content: center;
  transition: box-shadow .3s ease-in;
  background-color: $snow;

  span {
    @include font(h15, $coal);
  }

  &:hover {
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}
