@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  max-height: 700px;
  padding-top: 28px;
  overflow: hidden;
  align-items: center;

  .title {
    @include font(h20Semi, $color-gray-darker);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 44px;
    margin-bottom: 32px;
  }

  .description {
    @include font(h13Semi, $color-gray-darker);

    padding: 0px 16px;
    display: flex;
    word-wrap: break-word;
    line-height: 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    justify-content: center;
  }

  .labels {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .optionLabel {
      @include size(100%, 32px);
      @include font(h18Semi, $blue-darker-light);
      
      width: 100%;
      margin-bottom: 24px;
      transition: transform .1s ease-in;
      transform: scale(1);
      cursor: pointer;
      text-align: center;
  
      &:hover {
        transform: scale(1.05);
      }
    }

  }
}

.confirmButton {
  @include size(90%, 48px);
  @include border-radius(10px);

  margin-bottom: 48px;
  margin-right: 5%;
  margin-left: 5%;
  background-color: $blue;
  border: none!important;

  &:hover {
    background-color: $blue;
    box-shadow: 2px 5px 20px 0 rgba($coal, 0.15);
  }

  .text {
    @include font(h18, $snow);
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  max-height: 700px;
}
