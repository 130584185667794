@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  max-height: 700px;
  padding-top: 28px;
  overflow: hidden;
  align-items: center;

  .title {
    @include font(h20Semi, $color-gray-darker);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 44px;
    margin-bottom: 24px;
  }

  .labels {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .option {
      flex-direction: row;
      display: flex;
      width: 100%;
    }

    .lastOption {
      flex-direction: row;
      display: flex;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    .optionLabel {
      @include font(h13Med, $coal);

      margin-top: 10px;
      line-height: 33px;
      flex-grow: 1;
      cursor: pointer;
    }

    .optionLabelButton {
      @include size(100%, 60px);
      @include font(h18, $blue-darker-light);
      
      transition: transform .1s ease-in;
      transform: scale(1);
      cursor: pointer;
      text-align: center;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  max-height: 700px;
}
