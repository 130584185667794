@import '~styles/base';

.container {
  @include size(453px, auto);
  @include relative();
  @include flex($direction: column);

  align-items: center;
  justify-content: center;
  transition: box-shadow .3s ease-in;
  background-color: $snow;

  @include media-breakpoint-down (md) {
    @include size(90vw, auto);
  }

  .headerWrapper {
    @include flex();
    @include size(100%, 42px);

    align-items: center;
    justify-content: flex-start;

    img {
      @include size(auto);

      margin: 0 14px 0 20px;
    }

    .label {
      @include font(h18Semi, #1D232E);
  
      margin-bottom: 0;
    }
  }

  &:hover {
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}
