@import '~styles/base';

.container {  
  @include flex($direction: column, $align: center);

  width: 400px;
  margin-top: 50px;

  @include media-breakpoint-down (md) {
    width: 90vw;
  }
  
  .button {
    @include size(230px, 42px);
    @include font(h16Med);

    margin: 45px 5px 0;
    color: $blue-primary;
    background-color: $snow;
  }
}