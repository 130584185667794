@import '~styles/base';

.container {
  @include size(480px, 190px);
  @include flex($align: center, $direction: column);

  @include media-breakpoint-down (md) {
    @include size(100%, auto);
  }

  .loginType {
    @include font(h14, $snow);

    opacity: 0.7;
    margin-top: 48px;
    text-align: center;
  }
}
