@import '~normalize.css/normalize';
@import 'base';

// Some best-practice CSS that's useful for most apps
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Work Sans, sans-serif !important;
  color: $font-light-color;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
