@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  padding-top: 28px;
  overflow: hidden;
  align-items: center;

  .title {
    @include font(h20Semi, $color-gray-darker);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 44px;
    margin-bottom: 30px;
  }

  .optionLabel {
    @include size(100%, auto);
    @include font(h18, $blue-darker-light);

    padding: 48px 0px 24px 0px;
    transition: transform 0.1s ease-in;
    transform: scale(1);
    cursor: pointer;
    text-align: center;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);
}

.divider {
  width: 100%;
  height: 2px;
  background-color: $color-gray-light;
}

.dividerBottom {
  width: 100%;
  height: 1px;
  background-color: $color-gray-light;
}

.roommatesWrapper {
  width: 100%;
  max-height: 384px;
  overflow-y: scroll;
}

.list {
  @include list-unstyled();
}
