@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  max-height: 700px;
  padding-top: 28px;
  overflow: hidden;
  align-items: center;

  .title {
    @include font(h24Bold, $greenEmerald);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 44px;
    margin-bottom: 24px;
  }

  .animation {
    width: 100%;
    display: flex;
    justify-content: center;
    
    div {
      margin: -95px!important;
    }
  }

  .labels {
    width: 100%;
    padding: 0 36px;
    margin-top: 8px;
    align-items: center;
    justify-content: space-around;

    .blue {
      @include font(h18, $snow);
      width: 100%;

      margin-top: 16px;
      margin-bottom: 24px;
      background-color: $blueCurious;
      cursor: pointer;
      text-align: center;
    }
  }
}

.closeButton {
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 0.8;
  }
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  max-height: 700px;
}
