@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  padding-top: 28px;
  padding-bottom: 16px;
  overflow: hidden;
  align-items: center;

  .title {
    @include font(h20Semi, $color-gray-darker);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .optionLabel {
    @include size(100%, 64px);
    @include font(h18, $blue-darker-light);
    
    transition: transform .1s ease-in;
    transform: scale(1);
    cursor: pointer;
    text-align: center;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);
}
