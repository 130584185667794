@import '~styles/base';

.container {
  @include size(434px, auto);
  @include flex($direction: column, $align: center, $justify: space-between);

  min-height: 472px;
  cursor: pointer;
  opacity: 1;
  overflow: hidden;
  top: 1px !important;
  border-radius: 12px !important;
  position: absolute !important;
  background-color: $snow;
  box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.05);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  @include media-breakpoint-down (md) {
    @include size(90vw, 65vh);
  }

  &:hover {
    box-shadow: 0px 13px 87px 0 rgba(0, 0, 0, 0.15);
  }

  &.transition {
    transition: transform 50ms;
  }

  .name {
    @include font(h28Med, $color-gray-darker, $align: center, $line-height: 33px);
    @include flex($align: center);

    margin: 30px 16px 16px 16px;
  }

  .match {
    @include font(h27, $green);

    line-height: 28px;
    margin: 10px 0 10px 0;
  }

  .red {
    color: $redRoman
  }

  .green {
    color: $greenForest
  }

  .viewProfile {
    @include size(100%, 61px);
    @include flex($align: center, $justify: center);

    border-top: 0.5px solid $grayMishka2;
    background: none;
    border-left: none;
    border-right: none;
    border-bottom: none;

    img {
      margin: 2px 8px 0 0;
    }

    span {
      @include font(h18, $blueCerulean);

      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.04em;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    box-sizing: border-box;
  
    > * {
      margin: 0 5px;  // This creates 10px space between elements (5px on each side)
    }
  
    // Remove margin from first and last child to maintain alignment
    > :first-child {
      margin-left: 0;
    }
  
    > :last-child {
      margin-right: 0;
    }
  }
}

.bioContainer {
  margin-top: 10px;
  padding: 10px 30px;
  max-height: 100px;
  min-height: 50px;
  overflow: hidden;
  width: 100%;
}

.bio {
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  color: $coal;
}