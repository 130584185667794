@import '~styles/base';

.container {
  @include size(100px, auto);
  text-align: center;

  &.small {
    @include size(68px, 36px);
    @include flex($justify: flex-start);
  }

  img {
    @include size(100%, 50px);
    object-fit: contain;

    &.small {
      @include size(36px);
    }
  }

  p {
    font-weight: 600;
    color: rgba(12, 12, 12, 0.72);
  }
}
