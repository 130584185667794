@import '~styles/base';

.container {
  @include size(400px);
  @include relative();
  @include flex($justify: center, $align: center);
  
  @include media-breakpoint-down (md) {
    @include size(36vh);
  }

  &.small {
    @include size(100px);
  }

  .avatar {
    @include size(400px);
    @include border-radius(10%);

    object-fit: cover;

    @include media-breakpoint-down (md) {
      @include size(26vh);
    }

    &.small {
      @include size(82px);
    }
  }


  .percentWrapper {
    position: absolute;
    top: -40px;
    right: 5px;
  }
  .squareWrapper {
    @include size(400px);
    @include absolute();
    @include flex($justify: center, $align: center);

    @include media-breakpoint-down (md) {
      @include size(36vh);
    }

    &.small {
      @include size(100px);
    }

  
    .circularChart {
      @include size(400px);
      @include flex($justify: center, $align: center);

      display: block;
      margin: auto;

      @include media-breakpoint-down (md) {
        @include size(30.5vh);
      }

      &.small {
        @include size(400px);
      }
  
      .circle {
        stroke: $greenForest;
        fill: none;
        stroke-width: 1.2;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;

        &.white {
          stroke: $snow;
        }

        &.red {
          stroke: $redRoman;
        }

        &.gray {
          position: absolute;
          z-index: -1;
          animation: none;
          stroke: $grayMishka;
        }

        &.small {
          stroke-width: 2.3;
        }
      }
      .progressCircle {
        stroke-dasharray: 0, 239.389;
        animation: progress 1s ease-out forwards;
      }
    
      .percentText {
        opacity: 0;
        animation: fadeIn 0.5s ease-out 0.7s forwards;
      }
    }
  }

  .notClaimedContainer {
    @include size(400px);

    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;

    @include media-breakpoint-down (md) {
      @include size(26vh);
    }

    &.small {
      @include size(82px);
    }
  }

  .notClaimedWrapper {
    width: 400px;
    height: 28px;
    position: absolute;
    top: 60px;
    left: -100px;
    cursor: pointer;
    transform: rotate(-45deg);
    background-color: $purple-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
      text-align: center;
      font-size: 12px;
      color: white;
      transition: transform .1s ease-in;
  
      &:hover {
        transform: scale(1.04);
      }
    }

  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}

@keyframes progress {
  to {
    stroke-dasharray: attr(stroke-dasharray);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}