@import '~styles/base';

.container {
  @include flex($direction: column, $align: center);

  max-width: 430px;
  margin-top: 16px;

  @include media-breakpoint-down (md) {
    width: 90vw;
  }

  .lifestyle,
  .academicMajors,
  .housingOptions {
    @include flex($direction: row);
    @include size(100%, auto);

    .iconContainer {
      min-width: 50px;

      .icon {
        @include size(30px);

        margin-top: 12px;
        fill: $snow;
      }
    }

    .info {
      @include flex($direction: row, $justify: space-between);
      @include size(100%, auto);

      padding-bottom: 25px;

      .body {
        @include size(auto, auto);

        h3,
        p {
          @include font($color: $snow);
        }

        h3 {
          @include font(h15Semi);

          margin: 5px auto 15px;
          margin-right: 8px;
        }

        .options {
          @include size(420px, auto);
          @include flex($direction: row);
          @include list-unstyled();

          @include media-breakpoint-down (md) {
            width: 90vw;
          }

          margin-bottom: 0;
          flex-wrap: wrap;

          .option {
            @include size(210px, auto);
            @include font(h12Semi, $color: $snow);
            @include flex();

            white-space: pre-wrap;
            word-break: normal;
            max-width: 180px;
            margin-bottom: 5px;
            padding-right: 5px;

            .checkedIcon {
              @include size(13px);

              min-width: 13px;
              margin-top: 3px;
              margin-right: 10px;
              fill: $snow;
            }
          }
        }
      }
    }
  }

  .lifestyle {
    &.space {
      margin-top: 20px;
    }

    .info {
      border-top: 1px solid rgba($snow, 0.5);
      border-bottom: 1px solid rgba($snow, 0.5);

      &.space {
        border-top: none;
      }

      .body {
        p {
          @include font(h13, $line-height: 19px);
        }

        .editButton {
          @include size(173px, 43px);
        }
      }
    }
  }

  .academicMajors,
  .housingOptions {
    .info {
      @include relative();

      border-bottom: 1px solid rgba($snow, 0.5);
    }
  }

  footer {
    @include flex($direction: row);
    @include size(100%, auto);

    .button {
      @include size(50%, 42px);
      @include font(h16Med);

      margin: 45px 5px 0;
    }

    .white {
      color: $blue-primary;
      background-color: $snow;
    }
  }
}
