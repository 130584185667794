@import '~styles/base';

:global {
  html {
    position: relative !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: Work Sans, sans-serif;
    background: $base-gradient;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  * > &:focus {
    outline-color: $color-bittersweet;
  }

  button:focus {
    outline-color: $color-bittersweet;
  }
}
