@import "~styles/base";

.container {
  @include size(437px, 64px);
  @include border-radius(8px);
  @include flex($direction: row, $justify: space-between);

  cursor: pointer;
  margin: 9px 0;
  align-items: center;
  background-color: $snow;
  transition: box-shadow .3s ease-in;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  .arrow {
    @include size(10px, 18px);

    margin-right: 22px;
  }

  .label {
    @include font(h18Semi, $blue-primary);

    margin: 0 35px;
  }

  &:hover {
    box-shadow: 0 0 21px 0 rgba(15, 15, 15, 0.13);
  }
}
