@import '~styles/base';

.content {
  @include flex($justify: center);
  @include size(100%);
  @include relative();

  .indicator {
    margin-top: 370px;
  }
  
  .placeholder {
    @include size(827px, 461px);
    @include absolute(top 215px);

    object-fit: contain;
    align-self: center;
    z-index: -10;

    @include media-breakpoint-down(md) {
      @include size(96vw);
    }
  }
}

.toast {
  @include font(h15Semi);
  max-height: 48px;
}

.indicator {
  margin-top: 330px;
}