@import '~styles/base';

.container {
  @include size(371px, auto);
  @include flex($direction: column);

  overflow: hidden;
  align-items: center;

}

.titles {
  padding: 28px 30px 28px 30px;
}

.title {
  @include font(h20Semi, $color-gray-darker);

  text-align: center;
  margin-bottom: 0px;
}
.name {
  @include font(h20Semi, $color-gray-darker);

  text-align: center;
  margin-bottom: 0px;
}

.closeButton {
  display: none;
}

.modal {
  @include size(371px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  min-height: 465px;
  justify-content: center;
}

.modalConnected {
  @include size(100vw, 100vh);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  max-width: 100%;
  max-height: 100%;
}

.modalOverlayConnected {
  padding: 0;
}
