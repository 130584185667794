@import '~styles/base';

:global {
  .rc-slider-mark-text {
    @include font(h15, $color: $coal);

    top: 25px;
  }
  .rc-slider-disabled {
    background: transparent;
  }
}

