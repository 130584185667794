@import '~styles/base';

.container {
  @include size(436px, auto);
  @include border-radius(8px);
  @include relative();
  @include flex($direction: column);

  min-height: 550px;
  padding: 28px 33px;
  margin-bottom: 15px;
  align-items: center;
  transition: box-shadow .3s ease-in;
  background-color: $snow;

  @include media-breakpoint-down (md) {
    @include size(90vw, auto);
  }

  .avatar {
    @include size(140px);
    @include border-radius(100%);

    object-fit: cover;
    margin-bottom: 39px;
  }

  .name {
    @include font(h27, $color-gray-darker);
    @include flex($align: center, $justify: center);
    @include size(370px, 20px);
    
    margin-bottom: 42px;
    line-height: 33px;
    text-align: center;
  }

  .textarea {
    @include font(h18, $color-gray-darker);
    @include size(400px, 153px);

    padding: 8px 30px;
    border: none;
    z-index: 2;
    background-color: $color-transparent;
    -webkit-transform:translate3d(0,0,0);

    @include media-breakpoint-down (md) {
      @include size(80vw, 153px);
    }
  }

  .bubble {
    @include absolute (top 260px);
    @include size(371px, 175px);

    -webkit-transform:translate3d(0,0,0);
    z-index: 1;

    @include media-breakpoint-down (md) {
      @include size(80vw, 175px);
    }
  }

  .buttonWrapper {
    @include size(auto, 46px);
    @include border-radius(23px);
    @include flex();
    
    cursor: pointer;
    margin-top: 39px;
    padding: 0 24px;
    background-color: $blue-darker;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s ease-in;

    .label {
      @include font(h18, $font-light-color2);
    }

    &:hover {
      box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .error {
    @include font(h15Reg, $color-red);

    margin-top: 32px;
  }

  &:hover {
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

.knownWrapper {
  width: 100%;
  margin-top: 24px;
}
