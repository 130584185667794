@import '~styles/base';

.container {
  @include size(430px, auto);
  @include relative();
  @include flex($direction: row, $justify: space-between, $align: center);

  min-height: 50px;

  @include media-breakpoint-down (md) {
    @include size(100vw, auto);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: $purple-primary;
  }

  .iconBack {
    flex: 0 1 auto;
  }

  .labelWrapper {
    @include flex($direction: row, $justify: center, $align: center);
    @include absolute(left 70px right 76px);

    height: 50px;
    flex: 1;
    opacity: 1!important;
  }

  .labelWrapperDisabled {
    cursor: none;
  }

  .label {
    @include font(h21Semi, $color: $snow, $line-height: 1);
    @include flex($align: center, $justify: center);
    @include m(0);

    padding: 0 55px;
    flex-wrap: wrap;
    z-index: -1;
    padding: 0;
    text-align: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.active:hover {
    cursor: pointer;
    text-shadow: 2px 5px 20px rgba($coal, 0.15);
  }
}

.button {
  @include size(auto, 55px);
  min-width: 55px;
}
