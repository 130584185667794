@import '~styles/base';

.container {
  @include size(369px, auto);
  @include flex($align: center, $direction: column);
  @include relative();

  overflow: hidden;
  padding-top: 50px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .imageBackground {
    @include size(369px, 158px);
    @include absolute(top 0);
  }

  .avatar {
    @include size(133px);
    @include absolute(top 60px left 53px);
    @include border-radius(100%);

    object-fit: cover;
    border: 7px solid $snow;
  }

  .right {
    @include absolute(top 60px left 180px);
  }

  .title {
    @include font(h27, $blue-darker);

    margin: 156px 0 18px 0;
    text-align: center;
    line-height: 26px;
    animation: anim .7s ease-in-out;

    @keyframes anim {
      0% {
        transform: scale(.9)
      }
      30% {
        transform: scale(.9)
      }
      50% {
        transform: scale(1.05)
      }
      70% {
        transform: scale(.95)
      }
      100% {
        transform: scale(1)
      }
    }
  }

  .info {
    @include font(h18Reg, $coal);

    padding: 0 14px;
    word-wrap: break-word;
    line-height: 24px;
    text-align: center;
  }

  .buttonWrapper {
    @include size(309px, 50px);
    @include border-radius(25px);
    @include flex();
    
    cursor: pointer;
    margin: 20px 0;
    min-height: 50px;
    background-color: $blue-darker;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s ease-in;

    .label {
      @include font(h20, $font-light-color2);
    }

    &:hover {
      box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(369px, auto);
  @include border-radius(22.5px);
  @include flex($direction: column);
  @include relative();
  @include p(0);
}

.knownWrapper {
  width: 90%;
  margin-top: 24px;
  margin-bottom: 16px;
}
