@import '~styles/base';

.container {
  @include size(300px, auto);
  display: grid;
  position: relative;
  margin-bottom: 46px;

  &.errorContainer {
    margin-bottom: 0px;
  }

  .label {
    @include font(h14, $snow);
    margin-bottom: 12px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    &.errorLabel {
      color: $color-red;
    }
  }

  .helperText {
    @include font(h14, $color-red);

    margin: 8px 0 17px 0;
  }
}

.rightAdornment {
  position: absolute;
  right: 8px;
  top: 32px;
}

.paddingRight {
  padding-right: 24px!important;
}

.containerClass {

  input {
    @include size(300px, 50px);
    @include font(h18, $snow);
    @include p(0);

    border: none;
    background-color: $color-transparent;
    border-bottom: 2px solid $blue-light;
    transition: border-color 0.3s ease-in;

    &::placeholder {
      @include font(h18, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &::-webkit-input-placeholder {
      @include font(h18, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &::-moz-placeholder {
      @include font(h18, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      @include font(h18, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }

    &.error {
      margin: 0;
      border-bottom-color: $color-red;
    }

    &.disabled {
      opacity: 1;
      color: $color-gray-dark !important;
      -webkit-text-fill-color: $color-gray-dark !important;
    }

    &:focus {
      outline: none;
      border-color: $color-bittersweet;
    }
  }
}
