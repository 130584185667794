@import '~styles/base';

.container {
  @include size(100%, 164px);
  @include flex();

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconWhite {
    opacity: 0.2;
    fill: $snow;

    svg {
      fill: $snow;
    }
  }

  .iconBlue {
    opacity: 0.2;
    fill: #555555;

    svg {
      fill: #555555;
    }
  }

  .label {
    @include font(h13Med, rgba(85, 85, 85, 0.6));

    opacity: 0.6;
    margin-left: 16px;
  }

  .labelWhite {
    @include font(h13Med, #efeff4);

    opacity: 0.6;
    margin-left: 16px;
  }
}

.label,
.labelWhite {
  margin-bottom: 0;
}
