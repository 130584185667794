@import '~styles/base';

.container {
  @include size(320px, auto);
  @include flex($direction: column, $justify: center, $align: center);;

  color: $snow;

  .footerText {
    @include font(h13Med, $line-height: 22.5px);
    text-align: center;
    padding: 30px 0;
    @include m(0);
  }

  .contactUs {
    @include flex($direction: column, $justify: center, $align: center);
    @include font(h13Med, $line-height: 22.5px);
    text-align: center;

    p {
      margin-bottom: 10px;
    }

    &.button {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  margin-bottom: 30px;

}
