@import '~styles/base';

.container {
  @include size(437px, 82px);
  @include flex($align: center);
  @include border-radius(7.5px);

  cursor: pointer;
  margin: 5px 0;
  background-color: $snow;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  padding: 0 12px 0 13px;

  @include media-breakpoint-down (md) {
    width: 90vw;
  }

  .content {
    @include flex();

    align-items: center;

    .photo_border {
      @include size(50px);

      border: 3px solid $snow;
      border-radius: 100%;
      display: inline-block;
      box-shadow: 0 0 21px 0 rgba(15, 15, 15, 0.3);

      .photo {
        @include size(100%);
        @include border-radius(50%);

        object-fit: cover;
      }
    }
  }

  .name {
    @include font(h20Semi, $color-blue);

    margin-left: 18px;
    margin-bottom: 0;
  }

  .date {
    @include font(h13, $color-gray-dark);

    margin-bottom: 0;
    float: right;
  }

  &:hover,
  &:focus {
    background-color: darken($snow, 5%);
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}
