@import '~styles/base';

.container {
  @include size(392px, 125px);
  @include relative();
  @include flex();
  @include border-radius(8px);

  background-color: $snow;
  cursor: pointer;
  margin: 0 auto 14px auto;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 20px 0 rgba(15, 15, 15, 0.13);

  @include media-breakpoint-down (md) {
    @include size(85vw, 125px);
  }

  .arrow {
    @include absolute(right 15px top 15px);
    @include size(7px, 13px);
  }

  .avatar {
    @include size(48px);
    @include border-radius(50%);

    object-fit: cover;
    box-shadow: 0 0 16px 0 rgba(15, 15, 15, 0.15);
    border: solid 3px $snow;
    margin: 12px 0 0 14px;
  }

  .content {
    @include size(260px, auto);
    @include flex($direction: column);

    margin-top: 13px;
    padding-left: 17px;

    .top {
      @include flex($direction: column);

      text-align: left;
      margin-bottom: 3px;

      .date {
        @include font(h13Med, $color-gray-darker-light);
      }

      .title {
        @include font(h18, $blue-dark-lighter);
        @include size(auto, 50px);

        line-height: 2.5rem;
        overflow: hidden;
        white-space: pre-wrap;
      }
    }

    .bottom {
      @include flex($justify: space-between, $wrap: nowrap);

      .by {
        @include font(h13Med, $color-gray);
      }

      .name {
        @include font(h13Med, $coal);
      }

      .replies {
        @include font(h13Med, $color-gray-darker-light);
        min-width: fit-content;
      }
    }

    .infoBlock {
      @include size(296px, auto);
      @include flex($direction: column, $align: center);

      .row {
        @include size(296px, auto);
        @include flex($align: flex-end);

        .title {
          @include font(h18, $blue-darker, $align: left);
          @include size(175px, 22.5px);

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.unread {
            @include font(h18Semi, $blue-darker, $align: left);
          }
        }

        .date {
          @include size(120px, auto);
          @include font(h12Med, $color-gray-primary, $align: right);
        }
      }

      .message {
        @include font(h15, $color-gray-darker-light, $align: left);
        @include size(296px, auto);

        white-space: nowrap;
        overflow: hidden;
        margin-top: 5px;
        text-overflow: ellipsis;

        &.unread {
          @include font(h15Semi, $coal, $align: left);
        }
      }
    }

    .arrow {
      @include absolute(right 15px top 31px);
      @include size(7px, 13px);
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.2);
  }
}
