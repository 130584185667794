@import '~styles/base';

.topCard {
  transition: box-shadow 0.1s ease-in;
  animation: animIn 0.2s ease-in;
}

.inactive {
  transition: transform 0.2s ease-in;
  transform: perspective(38px) translate3d(0px, 40px, -4px);
}

.onCardBack {
  transition: transform 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes animIn {
  0% {
    transform: perspective(38px) translate3d(0px, 40px, -4px);
  }
  50% {
    transform: perspective(38px) translate3d(0px, 10px, 2px);
  }
  100% {
    transform: perspective(38px) translate3d(0px, 0px, 0px);
  }
}

.swipeOutLeft {
  animation: swipeOutLeft 0.4s ease-out;
  opacity: 0;
}

@keyframes swipeOutLeft {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: translate(-300px, -80px) rotate(-33deg);
    opacity: 0;
  }
}

.swipeOutRight {
  animation: swipeOutRight 0.4s ease-out;
  opacity: 0;
}

@keyframes swipeOutRight {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: translate(300px, -80px) rotate(33deg);
    opacity: 0;
  }
}

.skipOverlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000000;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(218, 99, 99, 0.9);

  span {
    @include font(h34Med, $snow, $align: center);

    margin-top: 16px;
  }
}

.likeOverlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000000;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(53, 177, 124, 0.9);

  span {
    @include font(h34Med, $snow, $align: center);

    margin-top: 16px;
  }
}
