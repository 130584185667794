@import '~styles/base';

.menuWrapper {
  @include flex($direction: column);

  z-index: 10;

  @include media-breakpoint-down (md) {
    @include fixed(top auto bottom 0px left 0px right 0px);
  }

  @include media-breakpoint-up (lg) {
    @include fixed(top 35px left 35px bottom 35px);
  }
}

.logo {
  @include size(176px, 32px);

  object-fit: contain;
}

.logoWrapper {
  margin: 8px 0 55px;

  @include media-breakpoint-down (md) {
    display: none;
  }
}