@import '~styles/base';

.inputGroup {
  @include size(399px, 65px);
  @include flex($align: stretch, $wrap: wrap);
  @include border-radius(32px);

  background: $snow;

  @include media-breakpoint-down (md) {
    @include size(70vw, 65px);
  }

  &.small {
    @include size(367px, 56px);
    @include border-radius(28px);

    padding-left: 47px;

    @include media-breakpoint-down (md) {
      @include size(70vw, 56px);
    }
  }

  .icon {
    @include size(28px);

    margin: 17px 14px 16px;
    opacity: 0.5;

    > svg {
      fill: #474747;
    }
  }

  .searchInput {
    @include border-radius(32px);
    @include font(h18, $color-gray-primary);

    flex: 1;
    outline: none;
    border: none;
    height: inherit;
    padding: 18px 20px 18px 5px;

    &::-webkit-input-placeholder {
      font-style: italic;
      color: $color-gray-primary;
    }

    &::-moz-placeholder {
      font-style: italic;
      color: $color-gray-primary;
    }

    &::-ms-placeholder {
      font-style: italic;
      color: $color-gray-primary;
    }

    &::placeholder {
      font-style: italic;
      color: $color-gray-primary;
    }
  }
}

.inputGroup:focus-within {
  border-color: $color-bittersweet;
}
