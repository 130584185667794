@import '~styles/base';

.topWrapper {
  .content {
    flex: 1;
    margin-top: 200px;
    margin-bottom: 120pc;
    position: relative;
    align-items: center;
  }

  .field {
    margin-bottom: 100pc;
    height: 200pc;
  }
}

.wrapper {
  height: 117px;
}

.container {  
  @include flex($direction: column, $align: center);

  width: 400px;
  margin-top: 50px;

  @include media-breakpoint-down (md) {
    width: 90vw;
  }
  
  .button {
    @include size(230px, 42px);
    @include font(h16Med);

    margin: 45px 5px 0;
    color: $blue-primary;
    background-color: $snow;
  }
}