@import '~styles/base';

textarea {
  @include size(100%, 240px);
  @include font(h20, $color-gray-darker);
  @include border-radius(8px);

  resize: none;
  margin-bottom: 24px;
  padding: 12px;
  background-color: $color-transparent;
  border: 2px solid $color-gray-light;


  @include media-breakpoint-down (md) {
    width: 80vw;
  }

  &::placeholder {
    @include font(h20, $color-gray-primary);
    opacity: 0.8;
    font-style: italic;
  }

  &::-webkit-input-placeholder {
    @include font(h20, $color-gray-primary);
    opacity: 0.8;
    font-style: italic;
  }

  &::-moz-placeholder {
    @include font(h20, $color-gray-primary);
    opacity: 0.8;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    @include font(h20, $color-gray-primary);
    opacity: 0.8;
    font-style: italic;
  }

  &.noMargin {
    margin: 5px 0 0 0;
  }
}

.error {
  @include font(h14, $color-red);

  margin-bottom: 23px;
}

.label {
  @include font(h16Med, $color-gray-darker, $align: center);

  padding-bottom: 12px;
}
