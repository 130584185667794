@import '~styles/base';

.wrapper {
  @include size(180px, auto);
  @include flex($direction: row);

  @include media-breakpoint-down (md) {
    @include size(100%, 55px);
  }

  .container {
    @include size(180px, 390px);
    @include flex($align: flex-start, $direction: column);
    @include list-unstyled();

    @include media-breakpoint-down (md) {
      @include flex($align: center, $direction: row);
      @include size(100%, 55px);

      background-color: $blue-dark;
    }
  }

  .indicatorWrapper {
    @include size(4px, 65px);
    @include flex($align: center, $justify: center);

    margin-top: 0;
    transition: margin-top .3s ease-in-out;

    @include media-breakpoint-down (md) {
      display: none;
    }

    div {
      @include size(4px, 52px);

      border-radius: 52px;
      background-color: $snow;
    }
  }
}