@import '~styles/base';

.desc {
  @include absolute(top -100% left -100%);
  color: $snow !important;

  &:focus {
    display: block;
    top: 0;
    left: 0;
  }
}
