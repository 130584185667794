@import '~styles/base';

.wrapper {
  @include flex($justify: center);
}

.container {
  background-color: $snow;
  border-radius: 10px;
  overflow: hidden;

  .title {
    @include font(h14BoldExtra, #5D6470);

    padding: 20px;
    height: 60px;
  }

  .item {
    height: 70px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #E7ECF3;
    transition: all .2s ease-in-out;

    &:hover,
    &:focus {
      background-color: darken($snow, 5%);
      // box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.15);
    }
    &:last-child {
      border-bottom: none;
    }

    span {
      @include font(h16Med, #1D232E);
    }

    img {
      width: 40px;
      margin: 0 20px;
    }

    button {
      padding: 0px;
      width: 100%;
    }

    .chevron {
      margin: 0px;
      width: 8px;
      height: 13px;
      margin-right: 26px;
    }

    .contentWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
