@import '~styles/base';


.container {
  @include size(300px, auto);
  display: grid;
  position: relative;
  margin-bottom: 46px;

  &.errorContainer {
    margin-bottom: 0px;
  }

  .labelWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .label {
    @include font(h14, $snow);
    margin: 0;
    margin-right: 5px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    &.errorLabel {
      color: $color-red;
      img { 
        path {
          fill: $color-red;
        }
      }
      
    }
  }

  .helperText {
    @include font(h14, $color-red);

    margin: 8px 0 17px 0;
  }

  .rightAdornment {
    line-height: 18px;
  }

  textarea {
    @include size(100%, 240px);
    @include font(h20, $snow);
  
    resize: none;
    margin-bottom: 24px;
    background-color: $color-transparent;
    border-bottom: 2px solid $color-gray-light;
  
  
    @include media-breakpoint-down (md) {
      width: 80vw;
    }
  
    &::placeholder {
      @include font(h14, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &::-webkit-input-placeholder {
      @include font(h14, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &::-moz-placeholder {
      @include font(h14, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      @include font(h14, $font-light-color);
      opacity: 0.6;
      font-style: italic;
    }
  
    &.noMargin {
      margin: 5px 0 0 0;
    }
  }
  
  .input {
    @include font(h14Thin, $snow);
    border: none;
    border-bottom: 1px solid $snow;
    border-radius: 0;
    padding: 12px 0px;
    &:focus {
      border-bottom: 2px solid $blue-light;
      outline: none;
    }
    margin-bottom: 0;
  }
  .errorInput {
    border-bottom: 2px solid $color-red;
  }
  
  .error {
    @include font(h16, $color-red);
  
    margin-bottom: 23px;
  }
  .disabled {
    opacity: 1;
    color: $color-gray-dark !important;
    -webkit-text-fill-color: $color-gray-dark !important;
  }
}


