@import '~styles/base';

.container {
  @include size(434px, 516px);
  @include flex($direction: column, $align: center);

  position: absolute !important;
  top: 0px !important;
  cursor: pointer;
  border-radius: 10px !important;
  opacity: 1 !important;
  margin-bottom: 0 !important;
  overflow: hidden;
  background-color: $snow !important;
  box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.05) !important;

  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  @include media-breakpoint-down (md) {
    @include size(90vw, 65vh);
  }

  &:hover {
    box-shadow: 0px 13px 87px 0 rgba(0, 0, 0, 0.15) !important;
  }

  .title {
    @include font(h28Med, $color-gray-darker, $align: center);

    line-height: 23px;
    margin: 30px 0 30px 0;
  }

  .avatar {
    @include size(140px);
    @include border-radius(100%);
    @include flex($justify: center, $align: flex-end);
    @include relative();

    @include media-breakpoint-down (md) {
      @include size(25.5vw);
    }

    position: absolute;
    overflow: hidden;

    img {
      pointer-events: none;
    }
  }

  &:focus {
    outline: 2px solid $color-bittersweet !important;
  }

  .circleWrapper {
    @include size(165px);
    @include flex($justify: center, $align: center);

    @include media-breakpoint-down (md) {
      @include size(30vw);
    }

    .circularChart {
      @include size(165px);
      @include flex($justify: center, $align: center);

      display: block;
      margin: auto;

      @include media-breakpoint-down (md) {
        @include size(30vw);
      }

      .circle {
        fill: none;
        stroke-width: 1.2;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
      }

      .circleGray {
        fill: none;
        stroke: $grayMishka;
        stroke-width: 1.2;
        stroke-linecap: round;
      }

      .red {
        stroke: $redRoman;
      }

      .green {
        stroke: $greenForest;
      }
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .desc {
    @include font(h16, $coal);

    flex: 1;
    display: flex;
    align-items: center;
    padding: 24px 47px;
    overflow: hidden;
    text-align: center;

    @include media-breakpoint-down (md) {
      @include font(h14Reg, $coal);

      padding: 16px 24px;
    }
  }

  .bottomContentWrapper {
    @include size(100%, 141px);
    @include flex($direction: column, $align: center);

    display: flex;
    border-top: 0.5px solid $grayMishka2;

    @include media-breakpoint-down (md) {
      @include size(100%, auto);
      min-height: 15vh;
    }

    .textGreen {
      @include font(h16, $greenMeadow, $align: center);

      margin-bottom: 12px;
    }

    .textRed {
      @include font(h16, $redRoman, $align: center);

      margin-bottom: 12px;
    }
  }
}

.lottieAnimation {
  height: 100px;

  @include media-breakpoint-down (md) {
    height: 12vh;
  }
}