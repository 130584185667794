@import '~styles/base';

.container {
  @include size(300px, auto);
  position: relative;
  margin-bottom: 46px;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .disabledLabel{
    padding-top: 7.5px;
    margin-right: 5px;
    @include font(h12, $color-gray-primary);
  }

  .label {
    @include font(h14, $snow);

    &.errorLabel {
      color: $color-red;
    }

    margin-bottom: 0;
    padding-right: 6px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .error {
    @include font(h14, $color-red);
    margin: 8px 0 17px 0;
  }
}

.rightAdornment {
  position: absolute;
  right: 28px;
  bottom: 16px;
}
