@import '~styles/base';

.container {
  @include size(100%, auto);
  @include flex($direction: column);

  animation: anim .5s;

  .tabContainer {
    @include size(332px, 39px);
    @include border-radius(19px);
    @include flex();

    align-items: center;
    align-self: center;
    border: 1px solid $snow;
    overflow: hidden;
    margin-bottom: 20px;

    .tab {
      @include size(166px, 100%);
      @include flex();

      cursor: pointer;
      background-color: $color-transparent;
      align-items: center;
      justify-content: center;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
      .label {
        @include size(100%);
        @include font(h13Semi, $snow);

        transform: scale(1);
        line-height: 37px;
        text-align: center;
        transition: all .1s ease-in;

        &.labelSelected {
          @include font(h13Semi, $blue-darker);
        }

        &:hover {
          transform: scale(1.02);
          opacity: 1;
        }
      }

      &.selected {
        opacity: 1;
        background-color: $snow;
      }
    }
  }

  .underline {
    @include size(221px, 2px);

    transition: margin-left .2s ease-out;
    margin-left: 0;
    opacity: .8;
    background-color: $snow;
  }

  .contentWrapper {
    @include flex($direction: column, $align: center);

    min-height: 110px;
    width: 100%;
  }

  .divider {
    @include size(442px, 1px);

    background-color: $snow;
    opacity: .6;
  }
}

@keyframes anim {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
