@import '~styles/base';

.container {
  @include flex($justify: center, $align: center, $direction: column);

  position: relative;
  @include border-radius(0%);

  .avatar {
    @include size(300px);
    @include border-radius(10%);

    object-fit: cover;
  }

  .uploadButton {
    @include size(42px);
    @include border-radius(50%);
    @include flex($justify: center, $align: center);

    cursor: pointer;
    background-color: #0290e6;
    border: solid 4px $snow;
    position: absolute;
    bottom: -3px;
    right: -4px;
    z-index: 10;

    &:focus {
      background-color: darken(#0290e6, 10%);
    }
  }

  &.withBorder {
    border: 7px solid $snow;

    .avatar {
      border: 1px solid #0093e8;
    }
  }
}
