@import '~styles/base';

.container {
  @include flex($align: center);
  @include size(358px, 50px);

  font-weight: 400;
  font-size: 16px;
  color: $coal;
  border-radius: 4px;
  margin-bottom: 13px;
  cursor: pointer;
  background-color: $snow;
  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

.icon {
  @include size(26px, auto);

  margin-right: 28px;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed	!important;
}
