@import '~styles/base';

.container {
  @include size(471px, auto);
  @include flex($direction: column);

  padding: 28px 8px 16px 8px;
  overflow: hidden;
  align-items: center;

  @include media-breakpoint-down (md) {
    width: 100%;
    max-height: 85vh;
    overflow-y: scroll;
  }

  .title {
    @include font(h20Semi, $color-gray-darker);

    word-wrap: break-word;
    line-height: 29px;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .space {
    @include media-breakpoint-down (md) {
      min-height: 54px
    }
  }

  .optionLabel {
    @include size(100%, 64px);
    @include font(h18, $blue-darker-light);

    background-color: #fefeff;
    transition: transform .1s ease-in;
    transform: scale(1);
    cursor: pointer;
    text-align: center;

    @include media-breakpoint-down (md) {
      @include border-radius(12px);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.item {
  align-self: stretch;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer;
  border: solid 2px rgba(0, 0, 0, 0.1);
  transition: all .2s ease-in;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.05) !important;

  &.selected {
    border: solid 2px $color-green !important;
  }

  &:hover {
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.15) !important;
  }

  .row {
    @include size(100%);

    padding: 8px 16px;
    min-height: 34px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .span1 {
      @include font(h16Med, #000000);

      width: 40%;
    }

    .span2 {
      @include font(h16, #000000);

      text-align: right;
    }
  }

  .divider {
    height: 0.5px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(471px, auto);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);
}