@import '~styles/base';

.container {
  @include flex($direction: column);
  @include size(100%, auto);

  margin: 10 0 20px 0;
  padding: 10px 30px 30px;
  background: $snow;

  .labels {
    @include flex($justify: space-between, $align: center);
    margin: 0 -20px 30px;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    span {
      @include size(90px, auto);
      @include font(h15, $color: $coal);
      text-align: left;

      &.activeLabel {
        @include font(h15Bold, $color: $blue-primary-light);
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}

:global {
  .rc-slider-rail {
    pointer-events: none;
  }
  
  .range-slider {
    .rc-slider-handle-1 {
    
      &::before {
        @include absolute(top 9px left 14.5px);
        @include font(h20Reg, $color: $snow);
  
        content: '<';
        letter-spacing: 3px;
        pointer-events: none;
      }
    }

    .rc-slider-handle-2 {
    
      &::before {
        @include absolute(top 9px left 18.5px);
        @include font(h20Reg, $color: $snow);
  
        content: '>';
        letter-spacing: 3px;
        pointer-events: none;
      }
    }
  }
}
