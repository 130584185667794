@import '~styles/base';

.container {
  @include size(74px);
  @include border-radius(100%);
  @include flex($direction: column);

  z-index: 10000;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: $snow;
  transition: transform .2s ease-in;
  transform: scale(1);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  @include media-breakpoint-down (md) {
    display: none;
  }

  img {
    width: 32px;
  }

  .label {
    @include font(h16, $dark);

    font-weight: normal;
    margin-top: 3px;

    &.skip {
      @include font(h12Med, $color-red-darker);

      font-weight: normal;
    }

    &.like {
      @include font(h12Med, $color-green);

      font-weight: normal;
    }
  }

  &:hover {
    transform: scale(1.04);
  }

  &.withAnimationRed {
    box-shadow: 0 0 0 rgba($color-red-darker, 1);
    animation: pulseRed .9s;
  }

  &.withAnimationGreen {
    box-shadow: 0 0 0 rgba($color-green, 1);
    animation: pulseGreen .9s;
  }
}

@keyframes pulseRed {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($color-red-darker, 1);
    box-shadow: 0 0 0 0 rgba($color-red-darker, 1);
  }

  70% {
    -moz-box-shadow: 0 0 0 30px rgba($color-red-darker, 0);
    box-shadow: 0 0 0 30px rgba($color-red-darker, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba($color-red-darker, 0);
    box-shadow: 0 0 0 0 rgba($color-red-darker, 0);
  }
}

@keyframes pulseGreen {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($color-green, 1);
    box-shadow: 0 0 0 0 rgba($color-green, 1);
  }

  70% {
    -moz-box-shadow: 0 0 0 30px rgba($color-green, 0);
    box-shadow: 0 0 0 30px rgba($color-green, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba($color-green, 0);
    box-shadow: 0 0 0 0 rgba($color-green, 0);
  }
}