@import '~styles/base';

.container {
  @include size(33px);
  @include flex($align: center, $justify: center);
  @include border-radius(100%);
  @include absolute(top 0 right 0);

  outline: none;
  border: none;
  margin-top: 10px;
  min-width: 33px;
  background-color: $snow;
  transition: box-shadow 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: $snow;
    box-shadow: 2px 5px 20px 0 rgba($coal, 0.15);
  }

  .editIcon {
    @include size(15px);

    fill: $blue-primary;
  }
}
