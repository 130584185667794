@import '~styles/base';

.container {
  @include size(286px, auto);
  @include flex($direction: column);

  flex: 1;
  align-items: center;

  .viewProfile {
    @include size(171px, 50px);
    @include border-radius(25px);
    @include flex();
    
    cursor: pointer;
    margin: auto;
    background-color: $blue-darker;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s ease-in;

    .label {
      @include font(h16Med, $font-light-color2);
    }

    &:hover {
      box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.closeButton {
  display: none;
}

.modal {
  @include size(286px, 367px);
  @include border-radius(12px);
  @include flex($direction: column);
  @include relative();
  @include p(0);

  overflow: hidden;
}

.logout {
  cursor: pointer;
  color: $blue-dark-lighter;
}