@import '~styles/base';

.container {
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &.animate {
    animation: pulse 1s ease-out;
  }

  &.animateSkip {
    animation: pulseRed 1s ease-out;
  }

  &.animateLike {
    animation: pulseGreen 1s ease-out;
  }


  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 5px;
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  &.skip {
    background-color: rgba($color-bittersweet, 0.1);
    color: $color-bittersweet;
    border: 2px solid $color-bittersweet;
    &:hover {
      background-color: rgba($color-bittersweet, 0.2); 
      border-color: darken($color-bittersweet, 10%);
    }
    
  }

  &.chat {
    background-color: rgba($blue-primary-light, 0.1);
    color: $blue-primary-light;
    &:hover { background-color: rgba($blue-primary-light, 0.2); }
  }

  &.view {
    background-color: rgba($blue-primary-light, 0.1);
    color: $blue-primary-light;
    &:hover { background-color: rgba($blue-primary-light, 0.2); }
  }

  &.request {
    background-color: rgba($purple-primary, 0.1);
    color: $purple-primary;
    &:hover { background-color: rgba($purple-primary, 0.2);}
  }

  &.like {
    background-color: rgba($green, 0.1);
    color: $green;
    border: 2px solid $green;
    &:hover { 
      background-color: rgba($green, 0.2); 
      border-color: darken($green, 10%);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseRed {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

@keyframes pulseGreen {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
}