@import '~styles/base';

.container {
  @include size(74px);
  @include border-radius(37px);
  @include flex($direction: column);

  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: $snow;
  transition: transform .2s ease-in;
  transform: scale(1);
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.15);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  &.skip {
    @include media-breakpoint-down (md) {
      position: absolute;
      top: 32vh;
      z-index: 10;
      left: -10px;
    }
  }

  &.like {
    @include media-breakpoint-down (md) {
      position: absolute;
      top: 32vh;
      z-index: 10;
      right: -10px;
    }
  }

  img {
    width: 32px;
  }

  .label {
    @include font(h16, $dark);

    font-weight: normal;
    margin-top: 5px;

    &.skip {
      @include font(h12Med, $color-red-darker);

      font-weight: normal;
    }

    &.like {
      @include font(h12Med, $color-green);

      font-weight: normal;
    }
  }

  &:hover {
    transform: scale(1.04);
  }
}