@import '~styles/base';

.svgLarge {
  width: 22px;
  height: 22px;
}

.wrapper {
  cursor: pointer;
  min-height: 27px
}